<template>
    <div class="sim-footer-menu">
        <!--  TODO: create a common component for collapsible button  -->
        <UiButton
            class="md:!cursor-text"
            content-class="sim-footer-menu__title justify-start"
            variant="bare"
            size="none"
            align="left"
            tabindex="-1"
            @click="toggleExpanded"
        >
            {{ menu.title }}
            <template #trailing>
                <IconChevron
                    class="md:hidden"
                    :up="isExpanded"
                    size="sm"
                />
            </template>
        </UiButton>

        <BaseUtilCollapse
            v-model="isExpanded"
            expanded-breakpoint="md"
        >
            <ul class="sim-footer-menu__list">
                <li v-for="(link, index) in menu.links" :key="index">
                    <!--  TODO: handle the case whn url is null  -->
                    <NuxtLink :to="link.url!" class="sim-footer-menu__link">
                        {{ link.label }}
                    </NuxtLink>
                </li>
            </ul>

            <slot />
        </BaseUtilCollapse>
    </div>
</template>

<script lang="ts" setup>
import type { FooterNavigationList } from './FooterComponent.vue'

const {
    menu,
} = defineProps<{
    menu: FooterNavigationList
}>()

const isOverLgBreakpoint = useScssBreakpoints().greaterOrEqual('md')
const isExpanded = ref<boolean>(false)

function toggleExpanded() {
    if (isOverLgBreakpoint.value) return
    isExpanded.value = !isExpanded.value
}

</script>

<style lang="scss" scoped>
.sim-footer-menu {
    display: flex;
    flex-direction: column;
}

.sim-footer-menu__title {
    text-transform: uppercase;

    @include sim-text-normal;
    @include sim-font-bold;
}

.sim-footer-menu__list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1.375rem;
}

.sim-footer-menu__link {
    @include sim-text-small;

    &:hover {
        text-decoration: underline;
    }
}
</style>
