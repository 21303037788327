<template>
    <div class="sim-customer-login">
        <!--  GUEST CUSTOMER  -->
        <template v-if="!authStore.isLoggedIn">
            <button class="sim-customer-login__button sim-menu-btn sim-mobile-menu-btn"
                    type="button"
                    :aria-label="$t('labels.log_in_short')"
                    @click="openModal(CustomerAuthFormType.LOGIN)"
            >
                <IconPerson width="24" height="24" aria-hidden="true" />

                <span class="hidden lg:block">
                    {{ $t('labels.log_in_short') }}
                </span>
            </button>

            <button class="sim-customer-login__button !hidden lg:!flex sim-menu-btn"
                    type="button"
                    @click="openModal(CustomerAuthFormType.REGISTRATION)"
            >
                {{ $t('labels.registration') }}
            </button>
        </template>

        <!--  LOGGED IN CUSTOMER  -->
        <template v-else>
            <button ref="userMenuButton"
                    class="sim-customer-login__button sim-menu-btn"
                    type="button"
                    :aria-controls="userMenuId"
                    :aria-expanded="isUserMenuDropdownOpen"
                    :aria-label="userMenuButtonAriaLabel"
                    @click="toggleUserMenu"
            >
                <IconPerson width="24"
                            height="24"
                            aria-hidden="true"
                            class="sim-customer-icon"
                />

                <span class="hidden lg:flex truncate" aria-hidden="true">
                    {{ authStore.customer?.getFullName() }}
                </span>
            </button>

            <!--  USER MENU  -->
            <LazyUiPopupCard
                v-if="isUserMenuDropdownMounted && $refs.userMenuButton"
                :id="userMenuId"
                v-model="isUserMenuDropdownOpen"
                class="sim-customer-login__popup"
                size="sm"
                :tip-location="isMobileWidth ? 'top-middle' : 'top-left'"
                :ignore-elements="[$refs.userMenuButton as HTMLElement]"
            >
                <UiPopupCardList>
                    <!--  MENU GROUP  -->
                    <div
                        v-for="(batch, index) in userMenuItems"
                        :key="index"
                        class="sim-customer-login__popup-item-group"
                    >
                        <template v-if="index == 1">
                            <hr>
                        </template>

                        <!--  MENU ITEM  -->
                        <UiPopupCardListItem
                            v-for="(item, item_index) in batch"
                            :key="item_index"
                            :href="item.routeName ? localePath(item.routeName) : undefined"
                            :on-click="item.onClick"
                        >
                            <!-- TODO :class="{ 'lg:hidden': item.isMobileOnly }"-->
                            {{ $t(item.labelPath) }}
                        </UiPopupCardListItem>
                    </div>
                </UiPopupCardList>
            </LazyUiPopupCard>

        </template>

        <!--  MODAL  -->
        <LazyCustomerAuthModal v-if="isModalMounted" v-model="isModalOpen" v-model:type="modalType" />

    </div>
</template>

<script lang="ts" setup>
import { CustomerAuthFormType } from '../../../../types/components'
import type { UiPopupCard } from '#components'

const { t } = useI18n()
const localePath = useLocalePath()

const authStore = useAuthStore()

const isMobileWidth = useIsMobileWidth()

const { is: isModalOpen, was: isModalMounted } = useLazyMount()
const modalType = ref<CustomerAuthFormType>(CustomerAuthFormType.LOGIN)
const openModal = (type: CustomerAuthFormType) => {
    isModalOpen.value = true
    modalType.value = type
}

const isUserMenuDropdownMounted = ref<boolean>(false)
const isUserMenuDropdownOpen = ref<boolean>(false)
useEvents().useOn('customer:logging-in', () => {
    isUserMenuDropdownOpen.value = false
})

const userMenuId = useId()
const userMenuButtonAriaLabel = computed(() => isUserMenuDropdownOpen.value
    ? t('accessibility.logged_in_user_button_close_menu', [authStore.customer?.getFullName()])
    : t('accessibility.logged_in_user_button_open_menu', [authStore.customer?.getFullName()])
)
function openUserMenu() {
    isUserMenuDropdownOpen.value = true
    isUserMenuDropdownMounted.value = true
}

function closeUserMenu() {
    isUserMenuDropdownOpen.value = false
}

function toggleUserMenu() {
    if (isUserMenuDropdownOpen.value) {
        closeUserMenu()
    } else {
        openUserMenu()
    }
}

const userMenuButton = ref<HTMLElement | null>(null)

const appConfig = useAppConfig()

type UserMenuItem = {
    onClick?: () => void
} & Omit<typeof appConfig.userMenu.links[0], 'routeName'> & {
    routeName?: string
}

// TODO: add hrefs
const userMenuItems = computed<UserMenuItem[][]>(() => [
    appConfig.userMenu.links,
    [
        {
            labelPath: 'labels.log_out',
            onClick: logOutCustomer,
        },
    ],
])

async function logOutCustomer() {
    await useAuth().logOut()
}

</script>

<style lang="scss" scoped>

.sim-customer-login {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sim-customer-login__button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 0.5rem;
    margin-inline: -0.5rem;
    max-width: 20rem;

    @include sim-text-normal;
}

.sim-customer-login__popup {
    position: absolute;
    bottom: -1rem;
    right: 50%;
    width: 12rem;

    transform: translate(50%, 100%);

    @include more-than(lg) {
        right: initial;
        left: 0;
        transform: translate(-14%, 100%);
    }
}

.sim-customer-login__popup-item-group hr {
    margin-block: 1rem;
}

/*  Log Out button styling  */
.sim-customer-login__popup-item-group:last-child :deep(.sim-popup-card__list-item:last-of-type .sim-popup-card__list-item-btn) {
    text-align: center;
    margin-top: -0.35rem;

    @include sim-font-medium;
}

.sim-customer-icon {
    color: $sim-c-success-700;

    @include more-than(lg) {
        color: inherit;
    }
}

</style>
