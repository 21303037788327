<template>
    <div class="sim-lang-switcher">
        <button ref="openPopupBtn"
                class="sim-lang-switcher__button sim-menu-btn"
                type="button"
                :aria-controls="localeMenuId"
                :aria-expanded="isDropdownOpen"
                :aria-label="$t('accessibility.switch_locale')"
                @click="toggleDropdown"
        >
            <span class="sim-lang-switcher__flag" aria-hidden="true">
                <CoreImg
                    class="sim-lang-switcher__flag-img"
                    :width="50"
                    :src="localeProperties.flag"
                    :alt="localeProperties.flagAlt"
                    loading="lazy"
                />
            </span>

            <span class="sim-lang-switcher__locale-name" aria-hidden="true">
                {{ localeProperties.code.toUpperCase() }}
            </span>

            <IconChevron size="xs"
                         :up="isDropdownOpen"
                         aria-hidden
            />
        </button>

        <!--  LANG SWITCHER MENU  -->
        <LazyUiPopupCard
            v-if="wasDropdownMounted && $refs.openPopupBtn"
            :id="localeMenuId"
            ref="popup"
            v-model="isDropdownOpen"
            class="sim-lang-switcher__popup"
            size="sm"
            tip-location="top-middle"
            :ignore-elements="[$refs.openPopupBtn as HTMLElement]"
        >
            <UiPopupCardList>
                <!--            <ul class="sim-lang-switcher__popup-content">-->
                <!--  TODO: refactor this, add a global utility  -->
                <UiPopupCardListItem
                    v-for="localeItem in availableLocales"
                    :key="localeItem.code"
                    :href="syncedPage?.getUrlForLang(localeItem.code) ? `${localeItem.domain}${syncedPage.getUrlForLang(localeItem.code)}` : switchLocalePath(localeItem.code)"
                    normal-anchor
                >
                    <span class="sim-lang-switcher__link">
                        <span class="sim-lang-switcher__flag">
                            <CoreImg
                                class="sim-lang-switcher__flag-img"
                                :width="50"
                                :src="localeItem.flag"
                                :alt="localeItem.flagAlt"
                                loading="lazy"
                            />
                        </span>
                        <span>
                            {{ localeItem.name }}
                        </span>
                    </span>
                </UiPopupCardListItem>
            </UiPopupCardList>
        </LazyUiPopupCard>
    </div>
</template>

<script lang="ts" setup>

const { localeProperties } = useI18n()
const localeMenuId = useId()

const { syncedPage } = useCurrentPage()

const availableLocales = useAvailableLocales()
const switchLocalePath = useSwitchLocalePath()

const { is: isDropdownOpen, was: wasDropdownMounted } = useLazyMount()

function toggleDropdown() {
    isDropdownOpen.value = !isDropdownOpen.value
}

const openPopupBtn = ref<HTMLElement | null>(null)

</script>

<style lang="scss" scoped>

.sim-lang-switcher {
    position: relative;
}

.sim-lang-switcher__button {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding-inline: 0.5rem;
    margin-inline: -0.5rem;

    @include accessibility-focus-outline-offset;
}

.sim-lang-switcher__link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.sim-lang-switcher__flag {
    width: 25px;
    height: 18px;

    background-color: #D9D9D9;
}

.sim-lang-switcher__flag-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sim-lang-switcher__popup {
    position: absolute;
    bottom: -1rem;
    right: 0;

    min-width: 6rem;

    transform: translateY(100%);
}

</style>
