<template>
    <div class="sim-customer-shortcuts">

<!-- TODO
        <NuxtLink v-if="authStore.isLoggedIn"
                  class="!hidden lg:!flex sim-menu-btn"
                  :to="localePath('customer-favourite-products')"
                  :aria-label="$t('accessibility.my_favorites')"
        >
            <IconHeart width="24" height="24" aria-hidden />
        </NuxtLink>
-->

        <CustomerCartButton />

    </div>
</template>

<script lang="ts" setup>

const authStore = useAuthStore()
const localePath = useLocalePath()

</script>

<style lang="scss" scoped>

.sim-customer-shortcuts {
    display: flex;
    //align-items: center;
    gap: 1rem;
}

</style>
