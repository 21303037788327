<template>
    <UiButton
        class="sim-customer-cart-button sim-menu-btn sim-mobile-menu-btn"
        variant="bare"
        size="none"
        :aria-label="$t('accessibility.cart')"
        @click="openDrawer"
    >
        <UiCountIndicator
            v-if="cart?.uniqueAmount"
            class="absolute -translate-y-1/2 translate-x-1/2"
            color="success"
            :count="cart.totalAmount!"
        />
        <IconShoppingBag aria-hidden />
    </UiButton>

    <!--  -------------------------------------------------------------------------------- -->
    <!--  CART DRAWER  -->
    <LazyCartDrawer
        v-if="wasDrawerMounted"
        v-model="isDrawerOpen"
        :highlighted-cart-item-id="highlightedCartItemId"
        @closed="highlightedCartItemId = null"
    />
</template>

<script lang="ts" setup>

// TODO: update aria label to include items count
const { cart } = useCart()

const { is: isDrawerOpen, was: wasDrawerMounted } = useLazyMount()

const openDrawer = () => {
    isDrawerOpen.value = true
}

const highlightedCartItemId = ref<number | null>(null)

const { useOn } = useEvents()
useOn('cart:item-added', ({ item }) => {
    highlightedCartItemId.value = item.id
    openDrawer()
})

</script>

<style lang="scss" scoped>

.sim-customer-cart-button {
    position: relative;
}
</style>
